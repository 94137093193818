.mat-radio-label-content {
    font-weight: normal !important;
}

mat-radio-button, .mat-radio-button ~ .mat-radio-button {
    display: block!important;
}
.mat-radio-container .mat-radio-outer-circle,
.mat-radio-container .mat-radio-inner-circle {
    border: 1px solid black!important;
    width: 20px!important;
}