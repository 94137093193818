td.mat-cell {
    text-overflow: ellipsis;
}

.text-center {
  .mat-sort-header-container {
      display: flex;
      justify-content: center;
  }
}

.mat-form-field-infix {
  width: 4rem !important;
  padding: 0 !important;
}


.left-justified {
  .mat-cell {
    text-align: left;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
  padding: 0 !important
}

.select-page .mat-paginator-range-actions {
  display: none;
}

.mat-paginator-page-size-select {
  margin: 2px !important;
  border: none !important;
  width: 0px !important;
}

.mat-paginator-page-size {
  flex-direction: column !important;
}

.uploads-pagination .mat-paginator-page-size-label {
      display: none;
}

//BOTTOM PAGINATION
.bottom-pagination .mat-paginator-page-size {
  display: none;
}

.bottom-pagination .mat-paginator-range-label {
  display: none;
}

.bottom-pagination .mat-paginator-container {
  justify-content: center;
}

.bottom-pagination .mat-paginator-range-actions{
background-color: white;
}

.bottom-pagination button{
  border: solid 1px #cfcfcf;
  border-radius: 0;
  color: #0740B9;
}

.bottom-pagination button:hover{
  border: solid 1px #0740B9;
  border-radius: 0;
  background-color: #0740B9;

}

.bottom-pagination .mat-paginator-icon {
  fill: #0740B9;
}

.bottom-pagination  button:hover .mat-paginator-icon {
  fill: white;
}

.mat-paginator-page-size .mat-select-trigger {
  font-size: 15px !important;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}

.whitespace-pre-wrap td {
  white-space: pre-wrap !important;
}
