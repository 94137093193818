mat-form-field {
  width: 100%;
}

.display.mat-input-element:disabled {
  color:black;
}


#news {
  #role {
    .mat-form-field-underline {
      display:none!important;
    }
    .mat-form-field-infix {
      border-top: 0 !important;
    }
  }
}

#dob {
  .mat-form-field-infix {
    border-top: 0 !important;
  }
}

#demographicForm {
  .mat-form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    width: 180px;
    border-top: 5px !important;
    padding-top: 20px !important;
  }
}

#fieldType {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    padding-top: 5px !important;
  }
}

#fieldTypeAgency{
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    padding-top: 5px !important;
  }
}

#ssidAgency {
  .mat-form-field-label-wrapper {
    position: absolute !important;
  }
}

#ssidLabel {
  .mat-form-field-label-wrapper {
    position: absolute !important;
  }
}

.mat-checkbox-layout {
  white-space: pre-wrap !important;
  align-items: unset !important;
}

.mat-checkbox-inner-container {
  margin: 0;
  margin-right: 8px !important;
}

.mat-checkbox-label, .mat-radio-label-content {
  font-size: small;
}

.mat-dropdown, .no-underline, .removeLine, .drdp-input {
  .mat-form-field-underline {
    display:none!important;
  }
}

#dob .mat-form-field-underline,
#ageSelect .mat-form-field-underline,
  #latinoSelect .mat-form-field-underline,
  #ethnicitySelect .mat-form-field-underline,
  #languageSelect .mat-form-field-underline,
  #eldSelect .mat-form-field-underline,
  #programSelect .mat-form-field-underline,
  #programCountySelect .mat-form-field-underline,
  #residenceCountySelect .mat-form-field-underline,
  #iepSelect .mat-form-field-underline,
  #primaryDisabilitySelect .mat-form-field-underline,
  #liabilitySelect .mat-form-field-underline,
  #adaptationSelect .mat-form-field-underline, 
  #Demographics .mat-form-field-underline, 
  #Field .mat-form-field-underline, 
  #Data .mat-form-field-underline {
    display:none !important;
  }

mat-form-field:not(.drdp-input) {
  .mat-form-field-wrapper {
    padding-bottom: 0!important;
  }

  .mat-form-field-underline {
    bottom: 0!important;
  }
}

.drdp-input {
  .mat-form-field-wrapper {
    padding:0!important;
  }

  .mat-form-field-infix {
    border:0!important;
    padding: 0!important;
  }
}