.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: lightgray;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 100vh;
    border: 2px solid lightgrey;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: white;
}