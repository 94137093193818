// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$DRDP_CLIENT-primary: mat.define-palette(mat.$indigo-palette);
$DRDP_CLIENT-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$DRDP_CLIENT-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$DRDP_CLIENT-theme: mat.define-light-theme(
  (
    color: (
      primary: $DRDP_CLIENT-primary,
      accent: $DRDP_CLIENT-accent,
      warn: $DRDP_CLIENT-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($DRDP_CLIENT-theme);
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./scss/base/module";
@import "./scss/components/module";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// @todo - need to cleanup style duplication, so many things being applied multiple times, commenting out for now but if we implement this we can target all mat-form-field elements

@layer base {
  .form-input-label-group {
    @apply flex flex-col h-full justify-between
  }

  .drdp-input {
    @apply rounded-lg shadow-md bg-white w-full;
  }

  .dropdown-item-holder {
    @apply bg-drdpblue-200 place-content-center mb-2 content-center rounded-md shadow-inner p-1;
  }

  .drdp-button {
    @apply py-1 rounded-lg mx-5 px-4 bg-drdpblue-300 hover:text-drdpblue-300 hover:bg-transparent hover:border-drdpblue-300 text-white cursor-pointer border-[0.1em] border-drdpblue-300;
  }
}
