.owl-dots {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding-top: 5px;
}

.owl-dots .owl-dot span {
  border: 1.5px solid black; 
  width: 20px !important;
  height: 20px !important;
  background-color: transparent !important;
}

.owl-dots .owl-dot.active span {
  background-color: $primary-color !important;
  border: none;
}
