.toast-success {
    background-color: #0740B9 !important;
    --mdc-snackbar-container-color: #0740B9 !important;
}

.toast-error {
    background-color: red !important;
    --mdc-snackbar-container-color: red !important;
}

.toast-warning {
    background-color: #FFCC00 !important;
    --mdc-snackbar-container-color: #FFCC00 !important;
}

.toast-info {
    background-color: orange !important;
    --mdc-snackbar-container-color: orange !important;
}