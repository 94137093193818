.mat-checkbox-label {
    font-weight: normal !important;
}

.mat-checkbox.font-medium {
  .mat-checkbox-layout {
    .mat-checkbox-label {
      font-weight: 500;
      }
    }
}

mat-checkbox label {
  display: block;
  margin: 0;
}

.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color:#D1D1D1 !important;
}

/* Custom checkbox style */
 .rounded-checkboxes .mat-checkbox-frame {
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid black; 
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.rounded-checkboxes .mat-checkbox-background {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background-color: white; /* Set the default background color */
  position: absolute; 
  top: 4.5px; /* Adjust vertical position */
  left: 4.5px; /* Adjust horizontal position */
}

.rounded-checkboxes .mat-checkbox-checked .mat-checkbox-background {
  background-color: #0740B9; /* Set the background color when checkbox is checked */
}

.rounded-checkboxes .mat-checkbox-checkmark {
  display: none; /* Hide the SVG checkmark */
}
