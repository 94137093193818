.drdp-input {
  .mat-form-field-wrapper {
    .mat-form-field-subscript-wrapper {
      .mat-error {
        margin-top: 1.25rem;
      }
    }
  }

  .mat-form-field-underline {
    display:none!important;
  }

  .mat-form-field-wrapper {
    padding:0!important;
  }

  .mat-form-field-infix {
    border: 0!important;
    padding: 0!important;
  }
}


  .mat-option.smaller {
    height: 20px !important;
    font-size: 14px !important;
    padding-left: 10px !important;
  }
  
  .filter-select.mat-select-panel {
    max-height: 100% !important;
  }

  .group-select.mat-select-panel {
    max-height: 50vh !important;
  }

  mat-select {
      background-color: white;
      padding: 5px;
      min-width: 150px;
      border: solid 1px #0740B9;
      border-radius: 10px;
  }

  .mat-select-placeholder {
    color: rgba(0, 0, 0, 0.66);
}


mat-select.mat-select {
  background-color: white;
  padding: 5px;
  min-width: 150px;
  border: solid 1px #0740B9;
  border-radius: 10px;
}

mat-select.mat-select.invalid {
  border: solid 1px #f44336;
}

.mat-select-value {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: small;
}


//SELECT INPUT MATERIAL
input#smartbox {
  background-color: white;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: -webkit-fill-available;
  height: 24px;
  border: solid 1px #0640B9;
  border-radius: 0.5rem;
}

input#smartboxInvalid {
  background-color: white;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: -webkit-fill-available;
  height: 24px;
  border: solid 1px red;
  border-radius: 0.5rem;
}

.no-underline {
  label.font-normal.required.m-0 {
    margin: 0.5rem 0;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
  .mat-form-field-infix {
    padding: 0 !important;
    border-top: 0 !important
  }
  .mat-form-field-underline {
    display:none!important;
  }
}

// .mat-form-field-label-wrapper {
//   position: static !important;
// }

.select-input .mat-form-field-underline {
  width: 0 !important;
}

.select-input label {
  padding-left: 3px !important;
}

.select-input .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.select-input .mat-select-arrow-wrapper {
  height: 0;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
  padding: 0 !important
}

input.mat-input-element {
  font-size: small;
}
